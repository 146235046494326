<template>
    <div>
        <v-row no-gutters >
            <v-col cols='12' md='3' v-for="(item, item_index) in items" :key='item_index' class='px-1'>
                <!-- <v-card flat outlined class=''> -->
                    <v-alert border="top" class="text-center pa-0 py-3" :color='item.color' text style="padding: 6px">
                        <h2 :style="'color:'+item.color">
                            {{$service.formatCurrency(item.value)}}
                            <span class=" grey--text small " style="font-size: 16px" v-if="item.isMoney">
                                د.ع
                           </span>
                        </h2>
                        <v-divider class="my-2"></v-divider>
                        <div class="font-weight-bold black--text">
                            <v-icon>
                                fi fi-rr-circle-small
                            </v-icon>
                            - {{item.text}} -
                            <v-icon>
                                fi fi-rr-circle-small
                            </v-icon>
                        </div>
                    </v-alert>
                <!-- </v-card> -->
            </v-col>
        </v-row>
    
    
        
        <!-- v-for="(progress, progress_index) in invoices" :key="progress_index" -->
        <v-row>
            <v-col cols="12" md="4">
                <v-card outlined>
                    <v-col>
                        <v-toolbar color="primary" dark tile>
                            <v-toolbar-title>
                                <h3>معدل انجاز الشحنات </h3>
                            </v-toolbar-title>
                        </v-toolbar>
                    </v-col>
                    <v-row class="ma-2" align='center'>
                        <v-col  v-for="(progress, progress_index) in invoices" :key="progress_index">
                            <v-progress-circular :rotate="360" :size="100" :width="10" :value="progress.value" :color="progress.color">
                                <h2>{{progress.value}}</h2>
                            </v-progress-circular>
                            
                        </v-col>
                        
                    </v-row>
                    <v-divider class="pt-2" />
                    <v-container class="font-weight-bold">
                        <v-alert text :color="progress.color" border="right" v-for="(progress, progress_index2) in invoices" :key="progress_index2">
                            <v-icon :color="progress.color">
                                fi fi-rr-circle-small
                            </v-icon>
                            {{progress.text}} :
                            <strong >
                                ( {{progress.value}} )
                            </strong>
                        </v-alert>
                    </v-container>
                </v-card>
            </v-col>
    
            <v-col cols="12" md="8">
                <v-card outlined height="483">
                    <v-col>
                        <v-toolbar color="primary" dark tile>
                            <v-toolbar-title>
                                <h3>معدل الشحنات المنجزه</h3>
                            </v-toolbar-title>
                        </v-toolbar>
                    </v-col>
                    <v-container fluid>
                        <barChart :dashboard="dashboard.bar" />
                    </v-container>
                </v-card>
            </v-col>
    
            
        </v-row>
    
        <!-- <v-row align="center" >
            <v-col cols='12' md='4' v-for="(clinic, clinic_index) in clinics" :key='clinic_index'>
                <v-card class="mt-4 " outlined >
                    <v-img style="position:relative; top:-15px;" :src="clinic.icon" height="64" width="64" class="mx-4" />
                    <v-card-text class="pt-0">
                        
                        <div class="font-weight-bold">
                            <v-icon>
                                fi fi-rr-circle-small
                            </v-icon>
                            عدد {{clinic.clinicName}}:
                            <strong class="black--text">
                                ( {{ clinic.clinics_count }} )
                            </strong>
                            <br/>
                            <v-icon>
                                fi fi-rr-circle-small
                            </v-icon>
                            عدد انواع {{clinic.text}}:
                            <strong class="black--text">
                                ( {{ clinic.services_count }} )
                            </strong>
                            <br/>
                            <v-icon>
                                fi fi-rr-circle-small
                            </v-icon>
                            عدد {{clinic.text}} المصروفة:
                            <strong class="black--text">
                                ( {{ clinic.services_paid }} )
                            </strong>
                            <br/>
                            <v-icon>
                                fi fi-rr-circle-small
                            </v-icon>
                            قيمة {{clinic.text}} المصروفة:
                            <strong class="black--text">
                                ( {{ $service.formatCurrency(clinic.services_cost) }} ) 
                            </strong>
                        </div>
                        <v-divider class="my-2"></v-divider>
                        <v-icon class="mr-2" small>
                            mdi-clock
                        </v-icon>
                        <span class=" font-weight-bold black--text">
                            {{clinic.clinicName}}
                        </span>
                    </v-card-text>
                </v-card>
            </v-col>
    
        </v-row> -->
    
        <!-- <v-row>
            <v-col cols="12" md="12">
                <v-card outlined c>
                    <v-col cols="12">
                        <v-toolbar color="primary" dark tile>
                            <v-toolbar-title>
                                <h3>إحصائيات تفصيلية للمحافظات</h3>
                            </v-toolbar-title>
                        </v-toolbar>
                    </v-col>
                    <v-container fluid class="text-center">
                        <iraqMap :mapDetails="mapDetails" />
                    </v-container>
                </v-card>
            </v-col>
        </v-row> -->
    
        
    
    </div>
    </template>
    
    <script>
    export default {
        components: {
            // lineChart: () => import('./charts/lineChart'),
            barChart: () => import('./charts/barChart'),
            // pieChart: () => import('./charts/pieChart'),
            // radialChart: () => import('./charts/radialChart'),
            // iraqMap: () => import('./charts/iraqMap'),
        },
        data() {
            return {
                dashboard: {
                    bar: {
                        series: [{
                            name: 'الشحنات',
                            data: [148128, 148008,148008,148008,148008,148008,148008,148008,]
                        }, ],
                        categories: ['قسم رقم 1', 'قسم رقم 2', 'قسم رقم 3', 'قسم رقم 4', 'قسم رقم 5', 'قسم رقم 6', 'قسم رقم 7', 'قسم رقم 8']
                    },
                    // bar: {
                    //     series: [],
                    //     categories: [new Date().getFullYear()]
                    // },
                    pie: {
                        series: [],
                        categories: []
                    },
                    radial: {
                        series: [],
                        categories: []
                    },
                },
                invoices: [
                    {
                        text: 'الشحنات المكتملة',
                        value: 0,
                        color: 'teal',
                    },
                    {
                        text: 'الشحنات المرفوضة',
                        value: 0,
                        color: 'error',
                    },
                    {
                        text: 'عدد الشحنات الكلي',
                        value: 0,
                        color: 'primary',
                    },
                ],
                
                items: [
                    {
                        text: 'عدد الشحنات المكتملة',
                        value: 0,
                        color: '#477DC8',
                        isMoney: false
                    },
                    {
                        text: 'عدد الشحنات المرفوضة',
                        value: 0,
                        color: '#006AA6',
                        isMoney: false
                    },
                    {
                        text: 'عدد الشحنات الكلي',
                        value: 2,
                        color: '#113255',
                        isMoney: false
                    },
                    {
                        text: 'عدد المخازن',
                        value: 5,
                        color: '#FFB905',
                        isMoney: false
                    },
                    {
                        text: 'عدد التجار',
                        value: 1,
                        color: '#FE9225',
                        isMoney: true
                    },
                    {
                        text: 'عدد مندوبين التوصيل',
                        value: 1,
                        color: '#59AD99',
                        isMoney: true
                    },
                    {
                        text: 'عدد مندوبين الاستلام',
                        value: 1,
                        color: '#5B2D78',
                        isMoney: true
                    },
                    {
                        text: 'عدد امناء المخازن',
                        value: 0,
                        color: '#CB4C4C',
                        isMoney: true
                    },
                ],
                clinics: [
                    {
                        key: 'doctors',
                        clinicName: "الاطباء والعيادات",
                        color: 'primary',
                        icon: require('@/assets/dashboard_icons/clinic.svg'),
                        clinics_count: 10,
                        services_count: 200,
                        services_paid: 100,
                        services_cost: 40000000,
                        text: 'الخدمات'
                    },
                    {
                        key: 'pharmacy',
                        clinicName: "الصيدليات",
                        color: 'primary',
                        icon: require('@/assets/dashboard_icons/pharmacy.svg'),
                        clinics_count: 10,
                        services_count: 200,
                        services_paid: 100,
                        services_cost: 40000000,
                        text: 'الادوية'
                    },
                    {
                        key: 'lab',
                        clinicName: "المختبرات",
                        color: 'primary',
                        icon: require('@/assets/dashboard_icons/lab.svg'),
                        clinics_count: 10,
                        services_count: 200,
                        services_paid: 100,
                        services_cost: 40000000,
                        text: 'التحاليل'
                    },
                    {
                        key: 'sonar',
                        clinicName: "عيادات السونار والدوبلر",
                        color: 'primary',
                        icon: require('@/assets/dashboard_icons/sonar.svg'),
                        clinics_count: 10,
                        services_count: 200,
                        services_paid: 100,
                        services_cost: 40000000,
                        text: 'الخدمات'
                    },
                    {
                        key: 'mri',
                        clinicName: "عيادات المفراس والرنين",
                        color: 'primary',
                        icon: require('@/assets/dashboard_icons/mri.svg'),
                        clinics_count: 10,
                        services_count: 200,
                        services_paid: 100,
                        services_cost: 40000000,
                        text: 'الخدمات'
                    },
                    {
                        key: 'x-ray',
                        clinicName: "عيادات الاشعة",
                        color: 'primary',
                        icon: require('@/assets/dashboard_icons/x-ray.svg'),
                        clinics_count: 10,
                        services_count: 200,
                        services_paid: 100,
                        services_cost: 40000000,
                        text: 'الخدمات'
                    },
                ]
            }
        },
    }
    </script>
    
    <style>
    .v-sheet--offset {
        top: -24px;
        position: relative;
    }
    </style>
    